import React from 'react';
import Layout from '../common/layouts';


export default ({props, data}) => (
  <Layout>
    <div className="grid lg:grid-cols-2 sm:grid-cols-1 content-center">
      <div className="bg-white">
        <img src={"PurosHiRes.jpg"}/>
      </div>

      <div className="grid bg-gray-100 place-content-center">

          <span className="db f2 display dark-gray">About</span>
        <div className="lh-copy f5 serif mt4">
          <h3>José Luis Fernández Pérez y Pablo Fernández Gallardo</h3>


          <h4>Premios y distinciones</h4>

          <ul>
            <li>Medalla RSME 2015.</li>
            <li>Doctorado honoris causa Universidad de La Laguna, 2009.</li>
            <li>Premio SEMA de divulgación en matemática aplicada 2004.</li>
          </ul>

        </div>
      </div>
    </div>

  </Layout>
)
